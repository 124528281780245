import React from "react";
import Card from "../src/components/Card";
import Tags from "../src/components/Tags";
import Activities from "../src/components/Activities";
import { tags, activities } from "../src/constants/mockData";
import "./App.css";

const percentageCompleted = 34;

function App() {
  return (
    <div className="App">
      <div className="main-container">
        <div className="app-container">
          <div className="hero">
            <Card title="Book your coach" description="Your personal coach to create your personalized routine" />
          </div>

          <div className="filter-container">
            <Tags tags={tags} />
          </div>

          <div className="activity-container">
            <div className="activity-header">
              <h2>Today’s Routine</h2>
              <p className="activity-percentage">{percentageCompleted}%</p>
            </div>
            <progress className="progess-bar" id="activity" value={percentageCompleted} max="100" />
            <div className="activities">
              <Activities activities={activities} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
