import React from "react";
import './index.css';

const Tag: React.FC<{ name: string }> = ({ name }) => {
  return (
    <>
      <div className="tag">{name}</div>
    </>
  );
};

export default Tag;
