import React from "react";
import { Tag } from "../../constants/types";
import ActivityTag from "../Tag"

const Tags: React.FC<{ tags: Tag[] }> = ({ tags }) => {
  return (
    <>
      {tags.map(({ label }, key) => {
        return <ActivityTag key={key} name={label} />;
      })}
    </>
  );
};

export default Tags;
