
import { Tag, Activity } from "./types";

export const tags: Tag[] = [
    {
        label: "Fitness",
    },
    {
        label: "Mental Health",
    },
];

export const activities: Activity[] = [
    {
        label: "Intermittent fasting",
        description: "Only eat between 12pm and 9pm",
        iconUrl: require('../images/fasting.png'),
        completed: false
    },
    {
        label: "Yoga",
        description: "10 min cardio",
        iconUrl: require('../images/yoga.png'),
        completed: false
    },
    {
        label: "Meditation",
        description: "10 min clarity",
        iconUrl: require('../images/meditation.png'),
        completed: true
    }
];