import React from "react";
import './index.css';

const Card: React.FC<{ title: string, description: string }> = ({ title, description }) => {
  return (
    <div className="card">
      <div className="card-title">{title}</div>
      <div className="card-description">{description}</div>
      <div className="card-button-container"><button className="card-button">Next</button></div>
    </div>
  );
};

export default Card;
