import React from "react";
import { Activity } from "../../constants/types";
import UserActivity from "../Activity";

const Activities: React.FC<{ activities: Activity[] }> = ({ activities }) => {
  return (
    <>
      {activities.map(({ label, description, completed, iconUrl }, key) => {
        return <UserActivity key={key} label={label} description={description} icon={iconUrl} completed={completed} />;
      })}
    </>
  );
};

export default Activities;
