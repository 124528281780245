import React from "react";
import "./index.css";
import completedImage from '../../images/completed.png'
import uncompletedImage from '../../images/check.png'


const Activity: React.FC<{ label: string; description: string; icon: any; completed: boolean }> = ({
  label,
  description,
  completed = false,
  icon,
}) => {
  return (
    <div className={`activity ${completed ? 'activity-completed' : ''}`}>
      <div className="activity-icon">
        <img alt="label" src={icon} />
      </div>
      <div>
        <div className="activity-label">{label}</div>
        <div className="activity-description">{description}</div>
      </div>
      <div>{completed ? <img alt="completed" src={completedImage} /> : <img alt="uncompleted" src={uncompletedImage} />}</div>
    </div>
  );
};

export default Activity;
